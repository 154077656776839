import React from 'react';
import PropTypes from 'prop-types';
import { createIntl, createIntlCache, FormattedMessage, defineMessages } from 'react-intl';

import Config from "../utils/config";
import code from "../utils/code";
import constants from "../utils/constants";
import { getCurrentTranslation } from '../utils/translations.js';
import classnames from "classnames";

const messages = defineMessages({
    activity_search_auto_placeholder: { id: 'activity_search_auto_placeholder', defaultMessage: "Zadejte kód nebo téma" },
});

var cache;
var intl;

export class SearchForm extends React.Component {
    static propTypes = {
        filter: PropTypes.bool,
        onInputChange: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired,
        onSubmit: PropTypes.func,
        isLoading: PropTypes.bool,
        isMobile: PropTypes.bool,
        isHomepage: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        cache = createIntlCache();
        intl = createIntl({ locale: Config.get('locale'), messages: getCurrentTranslation(Config.get('locale')) }, cache);

        this.inputRef = React.createRef();

        this.onInputChange = this.onInputChange.bind(this);
        this.onInputCapture = this.onInputCapture.bind(this);
    }

    componentWillMount() {
        this.setState({
            value: this.props.value,
            selectionStart: 0,
            selectionEnd: 0,
            cursorRepositioningNeeded: false,
            previousQuery: '',
        });
    }

    componentWillReceiveProps(props) {
        this.setState({
            value: props.value
        });
    }

    onInputCapture(event) {
        const { target } = event;
        const { selectionStart, selectionEnd } = target;

        this.setState({
            selectionStart,
            selectionEnd,
            cursorRepositioningNeeded: true,
        });
    }

    recordGa(searchTerm) {
        if (window.dataLayer) {
            window.dataLayer.push({ 'event': 'search', 'category': 'code', 'query': searchTerm });
        }
    }

    onInputChange(event) {
        const { target } = event;
        const { previousQuery } = this.state;
        let value = target.value;
        const originalValue = value;
        const isCode = code.isPrecode(value);

        let { selectionStart } = target;

        if (isCode) {
            value = value.replace(/[-\s]/, '');

            if (value.length > constants.PAGE_CODE_LENGTH) {
                selectionStart -= 1;

                value = value.substr(0, constants.PAGE_CODE_LENGTH);
            }

            if ((value.length > 2 && (previousQuery.length < originalValue.length)) || value.length > 3) {
                value = value.substr(0, 3) + '-' + value.substr(3);

                selectionStart = value.length;
            }

            if (value.length === 7) {
                this.recordGa(value);
            }
        }

        if (value !== originalValue) {
            this.setState({
                selectionStart: selectionStart,
                selectionEnd: selectionStart,
                cursorRepositioningNeeded: true,
            });
        }

        this.setState({
            previousQuery: originalValue,
        });

        this.props.onInputChange(value);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectionStart, selectionEnd, cursorRepositioningNeeded } = this.state;
        const { current } = this.inputRef;

        if (current && cursorRepositioningNeeded) {
            current.setSelectionRange(selectionStart, selectionEnd);
            this.setState({
                cursorRepositioningNeeded: false,
            });
        }
    }

    render() {
        const { filter, onSubmit, isLoading, isMobile, isHomepage } = this.props;
        const { value } = this.state;

        const isCode = /^\d{1,3}[-\s]?\d{0,3}$/.test(value);

        let formattedCode = "___-___";
        for (const char of value) {
            if (!isNaN(parseFloat(char)) && isFinite(char)) {
                formattedCode = formattedCode.replace('_', char);
            }
        }

        const placeholder = Config.get('searchPlaceholder') ?? intl.formatMessage(messages.activity_search_auto_placeholder);

        if (isMobile) {
            return (
                <form action={Config.get('searchFormUrl') ?? Config.get('searchUrl')} method="get" onSubmit={onSubmit} className={"form-inline mx-auto  text-center d-flex flex-row align-items-stretch form-search" + (filter ? '' : ' d-sm-flex')}>
                    <div className="search-bg">
                        {isCode &&
                            <span className="form-code">
                                {formattedCode}
                            </span>
                        }
                        <input type="text" className={"form-control form-control--search" + (isLoading ? " loading" : "")} autoComplete="off" value={value}
                            placeholder={placeholder} name="query" onChange={this.onInputChange} onClick={this.onInputCapture} ref={this.inputRef} />
                    </div>
                    <button type="submit" className="btn btn-search">
                        <FormattedMessage id='activity_search_auto_action' defaultMessage='Hledat' />
                    </button>
                </form>
            );
        }

        return (
            <form action={Config.get('searchFormUrl') ?? Config.get('searchUrl')} method="get" onSubmit={onSubmit} className={classnames('search-inline d-flex flex-row align-items-stretch form-search', {'with-start': isHomepage})} >
                <div className={classnames('search-ico', {'with-border': !isHomepage})}>
                    <svg viewBox="0 0 20 20"
                         fill="currentColor">
                        <path fill-rule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clip-rule="evenodd"/>
                    </svg>
                </div>
                {isCode &&
                    <span className="form-code">
                        {formattedCode}
                    </span>
                }
                <input type="text" className={classnames("form-control no-radius",{'with-border': !isHomepage, 'loading': isLoading})} autoComplete="off" value={value}
                    placeholder={placeholder} name="query" onChange={this.onInputChange} onClick={this.onInputCapture} ref={this.inputRef} />
                <button type="submit" className={classnames('btn btn-search--dark')}>
                    <FormattedMessage id='activity_search_auto_action' defaultMessage='Hledat' />
                </button>
            </form >
        );
    }
}
