import React from 'react';
import PropTypes from 'prop-types';
import constants from "../utils/constants";
import codeHelper from "../utils/code";

import { FormattedMessage } from 'react-intl';

export default class HintCode extends React.Component {
    static propTypes = {
        activities: PropTypes.shape({
            items: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.string.isRequired,
                link: PropTypes.string,
                className: PropTypes.string,
                subjectName: PropTypes.string,
                imageUrl: PropTypes.string,
            })).isRequired,
            count: PropTypes.number.isRequired
        }).isRequired,
        topics: PropTypes.shape({
            items: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.string.isRequired,
                shortName: PropTypes.string,
                link: PropTypes.string,
                className: PropTypes.string,
                subjectName: PropTypes.string,
                imageUrl: PropTypes.string,
            })).isRequired,
            count: PropTypes.number.isRequired
        }).isRequired,
        publication: PropTypes.shape({
            name: PropTypes.string.isRequired,
            link: PropTypes.string,
        }),
        linkMore: PropTypes.string,
        code: PropTypes.string.isRequired,
        searchedCode: PropTypes.string.isRequired,
    };

    render() {
        const { activities, publication, topics, code, searchedCode, linkMore } = this.props;
        let finalTopic = null, finalActivity = null;

        let formattedCode = "___-___";
        for (const char of code) {
            if (!isNaN(parseFloat(char)) && isFinite(char)) {
                formattedCode = formattedCode.replace('_', char);
            }
        }

        const isValidCode = codeHelper.isCode(code);
        const hasResults = activities.count > 0 || topics.count > 0;

        if (searchedCode.length === constants.PAGE_CODE_LENGTH + 1 && isValidCode) {
            if (activities.count === 1) {
                finalActivity = activities.items[0];
            }

            if (topics.count === 1) {
                finalTopic = topics.items[0];
            }
        }

        return (
            <div className="row align-items-center">
                <div className="col-md-6 col-lg-5 col-xl-3">
                    <div className="filter__hint__circle">
                        <h3 className="mt-3 mb-2">
                            <FormattedMessage id='activity_search_auto_code_h3'
                                defaultMessage='Kód{br}z{nbsp}publikace'
                                values={{ br: <br />, nbsp: <React.Fragment>&nbsp;</React.Fragment> }}
                            />
                        </h3>
                        <p className="hint--publication">{publication && publication.name}</p>
                        <p className="hint--code">{formattedCode}</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-7 col-xl-9">
                    {searchedCode.length < 3 &&
                        <div className="filter__hint__search">
                            <h3 className="mt-0">
                                <FormattedMessage id='activity_search_auto_code_short_h3'
                                    defaultMessage='Zadání kódu z publikace' />
                            </h3>
                            <p>
                                <FormattedMessage id='activity_search_auto_code_short_text'
                                    defaultMessage='Kód z{nbsp}publikace ti může zobrazit buď{nbsp}jedno cvičení a{nbsp}nebo{nbsp}i{nbsp}celé téma k{nbsp}procvičování.'
                                    values={{ nbsp: <React.Fragment>&nbsp;</React.Fragment> }}
                                />
                            </p>
                        </div>
                    }

                    {!isValidCode &&
                        <div className="filter__hint__search">
                            <h3 className="mt-0">
                                <FormattedMessage id='activity_search_auto_code_invalid_h3'
                                    defaultMessage='Nesprávný kód' />
                            </h3>
                            <p>
                                <FormattedMessage id='activity_search_auto_code_invalid_text'
                                    defaultMessage='Někde bude asi chyba, pro jistotu zkontroluj, zda zadáváš kód správně. Překlepnout se může každý :)' />
                            </p>
                        </div>
                    }

                    {searchedCode.length >= 3 && isValidCode && !hasResults &&
                        <div className="filter__hint__search">
                            <h3 className="mt-0">
                                <FormattedMessage id='activity_search_auto_code_no_result_h3' defaultMessage='Hledanému kódu neodpovídají žádná cvičení' />
                            </h3>
                            <p>
                                <FormattedMessage id='activity_search_auto_code_no_result_text' defaultMessage='Zadej platný kód z publikace.' />
                            </p>
                        </div>
                    }

                    {finalActivity &&
                        <div>
                            <h3 className="mt-2 mb-2">
                                <FormattedMessage id='activity_search_auto_code_found_activity_h3' defaultMessage='Nalezené cvičení:' />
                            </h3>
                            <div className="result">
                                <div className="result__img">
                                    <img src={finalActivity.imageUrl} alt="" />
                                </div>
                                <div className="result__content">
                                    <a href={finalActivity.link}
                                        className="result__title">{finalActivity.name}</a>
                                    <div className="result__meta">
                                        <span>{finalActivity.className}</span>
                                        <span>{finalActivity.subjectName}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {!finalActivity && finalTopic &&
                        <div>
                            <h3 className="mt-2 mb-2">
                                <FormattedMessage id='activity_search_auto_code_found_topic_h3' defaultMessage='Nalezené téma:' />
                            </h3>
                            <div className="result">
                                <div className="result__left">
                                    <div className="result__img">
                                        <img src={finalTopic.imageUrl} alt="" />
                                    </div>
                                    {finalTopic.comingSoon &&
                                        <div className="coming-soon">
                                            <FormattedMessage id='activity_search_auto_code_prepare' defaultMessage='Připravujeme' />
                                        </div>
                                    }
                                </div>
                                <div className="result__content">
                                    {finalTopic.comingSoon &&
                                        <span className="result__title">{finalTopic.shortName}</span>
                                    }
                                    {!finalTopic.comingSoon &&
                                        <a href={finalTopic.link} className="result__title">{finalTopic.shortName}</a>
                                    }
                                    <div className="result__meta">
                                        <span>{finalTopic.className}</span>
                                        <span>{finalTopic.subjectName}</span>
                                        {finalTopic.comingSoon &&
                                            <a href="#" data-fancybox data-src="#email-signup">
                                                <FormattedMessage id='activity_search_auto_code_signup' defaultMessage='Dejte mi vědět až to bude' />
                                            </a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {hasResults && isValidCode && searchedCode.length >= 3 && !finalActivity && !finalTopic &&
                        <div className="row">
                            <div className="col-md-6">
                                <h3 className="mt-0">
                                    <FormattedMessage id='activity_search_auto_code_practice_h3' defaultMessage='Cvičení' /> <span className="font-weight-light">({activities.count})</span>
                                </h3>

                                <ul className="list-links">
                                    {activities.items.map((item, idx) => (
                                        <li key={"activity-" + idx}><a href={item.link}>{item.name}</a></li>
                                    ))}
                                </ul>

                                {activities.count > 5 &&
                                    <p>
                                        <a href={linkMore} className="link-more">
                                            <FormattedMessage id='activity_search_auto_code_practice_action_show' defaultMessage='Zobrazit všechna nalezená cvičení' /> ({activities.count})
                                        </a>
                                    </p>
                                }
                            </div>
                            <div className="col-md-6">
                                <h3 className="mt-0">
                                    <FormattedMessage id='activity_search_auto_code_topic_h3' defaultMessage='Témata' /> <span className="font-weight-light">({topics.count})</span>
                                </h3>

                                <ul className="list-links">
                                    {topics.items.map((item, idx) => (
                                        <li key={"topic-" + idx}>
                                            {item.comingSoon && <React.Fragment>{item.name} – <em><FormattedMessage id='activity_search_auto_code_topic_prepare' defaultMessage='připravujeme' /></em></React.Fragment>}
                                            {!item.comingSoon && <a href={item.link}>{item.name}</a>}
                                        </li>
                                    ))}
                                </ul>

                                {topics.count > 5 &&
                                    <p>
                                        <a href={linkMore} className="link-more">
                                            <FormattedMessage id='activity_search_auto_topic_action_show' defaultMessage='Zobrazit všechna nalezená témata' /> ({topics.count})
                                        </a>
                                    </p>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
