export default {
    isPrecode(value) {
        return /^\d+[-\s]?\d*$/.test(value);
    },

    isCode(value) {
        return /^\d{1,3}[-\s]?\d{0,3}$/.test(value);
    },

    isValidCodeChange(newValue, oldValue) {
        return !newValue || !this.isCode(oldValue) || (this.isCode(oldValue) && this.isCode(newValue));
    }
}
