import React from 'react';
import PropTypes from 'prop-types';
import HintNoResults from "./HintNoResults";

import { FormattedMessage } from 'react-intl';

export default class HintResults extends React.Component {
    static propTypes = {
        activities: PropTypes.shape({
            items: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.string.isRequired,
                link: PropTypes.string,
            })).isRequired,
            count: PropTypes.number.isRequired
        }).isRequired,
        topics: PropTypes.shape({
            items: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.string.isRequired,
                link: PropTypes.string,
            })).isRequired,
            count: PropTypes.number.isRequired
        }).isRequired,
        subjects: PropTypes.shape({
            items: PropTypes.arrayOf(PropTypes.shape({
                subjectName: PropTypes.string.isRequired,
                className: PropTypes.string.isRequired,
                link: PropTypes.string,
            })).isRequired,
            count: PropTypes.number.isRequired
        }).isRequired,
        linkMore: PropTypes.string
    };

    render() {
        const { activities, subjects, topics, linkMore } = this.props;

        const noResults = activities.items.length === 0 && subjects.items.length === 0 && topics.items.length === 0;
        if (noResults) {
            return (
                <HintNoResults />
            )
        }

        return (
            <div className="row">
                {activities.count > 0 &&
                    <div className="col-md-4">
                        <h3 className="mt-0">
                            <FormattedMessage id='activity_search_auto_res_h3' defaultMessage='Cvičení' /> <span className="font-weight-light">({activities.count})</span>
                        </h3>
                        <ul className="list-links">
                            {activities.items.map((item, idx) => (
                                <li key={"activity-" + idx}><a href={item.link}>{item.name}</a></li>
                            ))}
                        </ul>

                        {activities.count > 5 &&
                            <p>
                                <a href={linkMore} className="link-more">
                                    <FormattedMessage id='activity_search_auto_res_more' defaultMessage='Zobrazit všechna nalezená cvičení' /> ({activities.count})
                                </a>
                            </p>
                        }
                    </div>
                }
                {topics.count > 0 &&
                    <div className="col-md-4">
                        <h3 className="mt-0"><FormattedMessage id='activity_search_auto_topic_h3' defaultMessage='Témata' /> <span className="font-weight-light">({topics.count})</span></h3>

                        <ul className="list-links">
                            {topics.items.map((item, idx) => (
                                <li key={"topic-" + idx}>
                                    {item.comingSoon && <React.Fragment>{item.name} – <em><FormattedMessage id='activity_search_auto_topic_prepare' defaultMessage='připravujeme' /></em></React.Fragment>}
                                    {!item.comingSoon && <a href={item.link}>{item.name}</a>}
                                </li>
                            ))}
                        </ul>

                        {topics.count > 5 &&
                            <p>
                                <a href={linkMore} className="link-more"><FormattedMessage id='activity_search_auto_topic_more' defaultMessage='Zobrazit všechna nalezená témata' /> ({topics.count})</a>
                            </p>
                        }

                    </div>
                }
                {subjects.count > 0 &&
                    <div className="col-md-4">
                        <h3 className="mt-0"><FormattedMessage id='activity_search_auto_subject_h3' defaultMessage='Předměty' /> <span className="font-weight-light">({subjects.count})</span></h3>

                        <ul className="list-links">
                            {subjects.items.map((item, idx) => (
                                <li key={"subject-" + idx}><a href={item.link}>{item.subjectName} ({item.className})</a></li>
                            ))}
                        </ul>

                        {subjects.count > 5 &&
                            <p>
                                <a href={linkMore} className="link-more"><FormattedMessage id='activity_search_auto_subject_more' defaultMessage='Zobrazit všechny nalezené předměty' /> ({subjects.count})</a>
                            </p>
                        }
                    </div>
                }
            </div>
        );
    }
}
