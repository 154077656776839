import React from 'react';

import { FormattedMessage } from 'react-intl';

export default class HintNoResults extends React.Component {
    static propTypes = {};

    render() {
        const { } = this.props;

        return (
            <div className="row align-items-center">
                <div className="col-md-6 col-lg-5 col-xl-3">
                    <div className="filter__hint__circle">
                        <h3 className="mt-3 mb-2">
                            <FormattedMessage id='activity_search_auto_nores_1_h3' defaultMessage='Cvičení z učebnice' />
                        </h3>
                        <p>
                            <FormattedMessage id='activity_search_auto_nores_1_text' defaultMessage='Do vyhledávání můžeš vložit šestimístný kód z tvojí učebnice' />
                        </p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-7 col-xl-9">
                    <div className="filter__hint__search">
                        <h3 className="mt-0">
                            <FormattedMessage id='activity_search_auto_nores_2_h3' defaultMessage='Žádné výsledky nebyly nalezeny' />
                        </h3>
                        <p>
                            <FormattedMessage id='activity_search_auto_nores_2_text' defaultMessage='Zkuste vámi vyhledávaný výraz trochu upravit a určitě něco najdeme.' />
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}