import React from 'react';
import PropTypes from 'prop-types';
import {IntlProvider, FormattedMessage} from 'react-intl';

import Config from "../utils/config";
import HintResults from "./HintResults";
import HintPlaceholder from "./HintPlaceholder";
import HintCode from "./HintCode";
import {SearchForm} from "./SearchForm";
import code from "../utils/code";

import {getCurrentTranslation} from '../utils/translations.js';
import classnames from "classnames";

export default class SearchAutocomplete extends React.Component {
    static propTypes = {
        filter: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.debounceTimer = null;
        this.displayedRequest = 0;

        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount() {
        this.setState({
            hint: null,
            searchedQuery: '',
            query: '',
            isLoading: false,
        });
    }

    componentDidMount() {
        if (this.props.filter) {
            const $ = window.$;
            $(".filter__search .form-control")
                .focusin(function () {
                    $.doTimeout('lost_focus')
                    $(".filter").addClass("filter--search");
                    $(".filter__main .filter__options").addClass("d-none");

                    $.doTimeout('focus', 400, function () {
                        $(".filter").addClass("filter--search-delay");
                    });
                })
                .focusout(function () {
                    $.doTimeout('focus');

                    $.doTimeout('lost_focus', 200, function () {
                        $(".filter").removeClass("filter--search filter--search-delay");
                        $(".filter__main .filter__options").removeClass("d-none");
                    });
                });
        }
    }

    onInputChange(v) {
        let value = v;

        if (!code.isValidCodeChange(value, this.state.query)) {
            return;
        }

        this.setState({query: value});
        if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
        }

        // Request after 100 ms
        this.debounceTimer = setTimeout(() => this.getSuggestions(value), 250);
    }

    onSubmit(event) {
        const query = this.state.query;
        const isCode = code.isCode(query);

        if (isCode) {
            event.preventDefault();
            window.location = Config.get('searchUrl') + '?query=' + query;
            return false;
        }
    }

    getSuggestions(input) {
        if (input) {
            let query = input;
            const resource = Config.get('searchSuggestionUrl') + '?query=' + query;

            const requestTime = (new Date()).getTime();
            this.lastRequestTime = requestTime;

            this.setState({
                isLoading: true,
            });
            fetch(resource)
                .then((response) => {
                    return response.json();
                })
                .then((json) => {
                    if (requestTime > this.displayedRequest) {
                        this.displayedRequest = requestTime;

                        this.setState({
                            searchedQuery: input,
                            hint: json,
                            isLoading: requestTime !== this.lastRequestTime,
                        });
                    }
                });
        } else {
            this.setState({
                hint: null,
                searchedQuery: ''
            });
        }
    }

    render() {
        const {filter} = this.props;
        const {hint, query, searchedQuery, isLoading} = this.state;

        const isCode = code.isCode(searchedQuery);
        const linkMore = Config.get('searchUrl') + (searchedQuery ? ('?query=' + searchedQuery) : '');
        const hasContent = hint && query !== '';
        const locale = Config.get('locale');
        const homepage = Config.get('homepage') === 'true';
        const profileDetail = Config.get('profileDetail') === 'true';

        return (
            <IntlProvider locale={locale} defaultLocale="cs" messages={getCurrentTranslation(locale)}>
                <div className={classnames( "search-inline search-inline--lg", {"d-block": profileDetail, 'position-relative': filter})}>
                    {filter &&
                    <a key="search_link" className="filter__label d-block d-xl-none" href="#filter-search-lightbox" data-fancybox=""
                       data-options="{&quot;baseClass&quot;:&quot;fancybox-filter&quot;}">
                        <FormattedMessage id='activity_search_auto_code_action_search'
                                          defaultMessage='Vyhledat cvičení'/>
                    </a>
                    }

                    <SearchForm key="search_form" onInputChange={this.onInputChange} onSubmit={this.onSubmit}
                                value={query}
                                filter={filter} isLoading={isLoading} isHomepage={homepage}/>
                    <div key="search_hint" className={filter ? "filter__hint" : 'hint'}>
                        {!hasContent &&
                        <HintPlaceholder />
                        }

                        {hasContent && !isCode &&
                        <HintResults linkMore={linkMore} activities={hint.activities} subjects={hint.subjects}
                                     topics={hint.topics}/>
                        }

                        {hasContent && isCode &&
                        <HintCode linkMore={linkMore} activities={hint.activities} topics={hint.topics}
                                  searchedCode={searchedQuery} code={query}
                                  publication={hint.publications.count > 0 ? hint.publications.items[0] : null}/>
                        }
                    </div>
                </div>
                <div className="search-box--backdrop" />
            </IntlProvider>
        );
    }
}
