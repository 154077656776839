import React from 'react';

import { FormattedMessage } from 'react-intl';

export default class HintPlaceholder extends React.Component {
    static propTypes = {};

    render() {
        const { } = this.props;

        return (
            <div className="row align-items-center">
                <div className="col-md-6 col-lg-5 col-xl-3">
                    <div className="filter__hint__circle">
                        <h3 className="mt-3 mb-2">
                            <FormattedMessage id='activity_search_auto_placeholder_1_h3'
                                defaultMessage='Cvičení{br}z{nbsp}publikace'
                                values={{ br: <br />, nbsp: <React.Fragment>&nbsp;</React.Fragment> }}
                            />
                        </h3>
                        <p>
                            <FormattedMessage id='activity_search_auto_placeholder_1_text'
                                defaultMessage='Do{nbsp}vyhledávání můžeš vložit šestimístný kód z{nbsp}tvého sešitu'
                                values={{ nbsp: <React.Fragment>&nbsp;</React.Fragment> }}
                            />
                        </p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-7 col-xl-9">
                    <div className="filter__hint__search">
                        <h3 className="mt-0">
                            <FormattedMessage id='activity_search_auto_placeholder_2_h3'
                                defaultMessage='Jak na hledání správného cvičení?'
                            />
                        </h3>
                        <p>
                            <FormattedMessage id='activity_search_auto_placeholder_2_text'
                                defaultMessage='Zadej téma k{nbsp}procvičení. Určitě pro tebe něco najdeme.'
                                values={{ nbsp: <React.Fragment>&nbsp;</React.Fragment> }}
                            />
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}